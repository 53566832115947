<style lang="scss" scoped>
.duty_detail_content {
	font-size: 0.24rem;
	line-height: 0.5rem;
	color: $font_color_main;
	padding: 0 0.3rem 0.6rem;
	text-indent: 2em;
    word-break: break-all;
}
::v-deep .van-popup {
	z-index: 3001 !important;
}
</style>
<template>
	<div class="bottom_info_pop">
		<van-action-sheet v-model="isShow" :title="title">
			<div class="content duty_detail_content">
				<slot>
					<div v-html="content"></div>
				</slot>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import { ActionSheet } from 'vant';

export default {
	name: 'bottomInfoPop',
	components: {
		[ActionSheet.name]: ActionSheet,
	},
	model: {
		prop: 'show',
		event: 'change',
	},
	props: {
		show: {
			type: [Boolean, String, Number],
			required: true,
		},

		title: {
			type: String,
			default: '标题',
		},

		content: {
			type: String,
			default: '内容',
		},
	},
	computed: {
		isShow: {
			get: function () {
				return this.show;
			},
			set: function (v) {
				this.$emit('change', v);
			},
		},
	},
};
</script>
